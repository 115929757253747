import { Link } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components/macro';
import Container from './container';

const StyledContainer = styled(Container)`
  background-color: #2ab1f5;
`;

const StyledHeader = styled.div`
  width: 100%;
  display: flex;
  padding: 2rem 4rem;
  justify-content: space-between;
  max-width: calc(1280px + 8rem);
  flex-wrap: wrap;
`;

const LogoLink = styled(Link)`
  color: white;
  font-family: 'Brandon Grotesque';
  font-weight: bold;
  font-size: 2.6rem;
  text-decoration: none;
`;

const Navbar = styled.nav`
  display: flex;
  align-items: center;
`;

const NavLink = styled.a`
  color: #fdfdfe;
  font-family: 'Brandon Grotesque';
  font-weight: bold;
  text-decoration: none;
  margin-left: 2rem;
  &:first-child {
    margin-left: 0;
  }
`;

const Header = ({ siteTitle }) => {
  return (
    <>
      <Helmet>
        <title>Archmob</title>
      </Helmet>
      <StyledContainer>
        <StyledHeader>
          <LogoLink to="/">{siteTitle}</LogoLink>
          <Navbar>
            <NavLink href="#our-group">Our Group</NavLink>
            <NavLink href="#references">References</NavLink>
            <NavLink href="#contact">Reach Out</NavLink>
          </Navbar>
        </StyledHeader>
      </StyledContainer>
    </>
  );
};

export default Header;
