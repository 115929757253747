import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import styled from 'styled-components/macro';
import GlobalStyle from '../globalStyle';
import Footer from './footer';
import Header from './header';

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const LayoutBody = styled.main`
  flex: 1;
`;

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <LayoutContainer>
      <GlobalStyle />
      <Header siteTitle={data.site.siteMetadata.title} />
      <LayoutBody>{children}</LayoutBody>
      <Footer />
    </LayoutContainer>
  );
};

export default Layout;
