import { Link } from 'gatsby';
import React from 'react';
import { FaFacebook, FaLinkedin, FaTwitterSquare } from 'react-icons/fa';
import styled from 'styled-components/macro';

const StyledFooter = styled.footer`
  display: flex;
  justify-content: center;
  background-color: #0688ca;
`;

const Container = styled.div`
  width: 100%;
  max-width: calc(1280px + 8rem);
  padding: 1.2rem 4rem;
  display: flex;

  @media (max-width: 600px) {
    flex-direction: column;
  }

  a {
    margin-right: 1rem;
    &:last-child {
      margin-right: 0;
    }
    svg {
      color: white;
    }
  }

  p {
    font-size: 0.8rem;
    color: white;
    font-weight: bold;
    font-family: 'Brandon Grotesque';
    margin: 0;
    margin-left: 5rem;

    @media (max-width: 600px) {
      margin-left: 0;
    }
  }
`;

const Footer = () => {
  return (
    <StyledFooter>
      <Container>
        <div>
          <Link to="/">
            <FaFacebook />
          </Link>
          <Link to="/">
            <FaLinkedin />
          </Link>
          <Link to="/">
            <FaTwitterSquare />
          </Link>
        </div>
        <p>
          hi@archmob.com
          <br />
          +63 917 847 4590
        </p>
        <p>
          24 Raffles Place #27-01 Clifford Centre
          <br />
          Singapore 048261
        </p>
      </Container>
    </StyledFooter>
  );
};

export default Footer;
